.container {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #111;
  width: 100%;
  max-width: 800px;
  padding: 24px;
  margin-bottom: 16px;
  margin-right: auto;
  margin-left: auto;
  & img {
    height: 80px;
    align-self: flex-start;
  }
}
.innerContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.leftInner {
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & div {
    padding-top: 24px;
    &:first-child {
      padding-top: 0px
    }
  }
}
.rightInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}