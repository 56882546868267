.container {
  position: sticky;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: #111;
  width: 100%;
  height: 64px;
  top: 0;
  padding: 0 36px;
  z-index: 100;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  
  & img {
    height: 16px;
  }
}
.items {
  display: flex;
  flex-direction: row-reverse;

  & div {
    color: white;
    margin-right: 32px;
    align-items: center;
    font-size: 18px;
  }

}