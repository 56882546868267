@font-face {
  font-family: 'Shabnam-FD-WOL';
  src: local('Shabnam-FD-WOL'), url(./assets/fonts/Shabnam.ttf) format('truetype');
}
body {
  margin: 0;
  font-family: Shabnam-FD-WOL !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  -webkit-tap-highlight-color: transparent !important;
}

* {
  box-sizing: border-box;
}

.datepicker.android .datepicker-wheel, .datepicker.android-dark .datepicker-wheel{
  border-top: 2px solid #dedede !important;
  border-bottom: 2px solid #dedede !important;
}
.datepicker.android .datepicker-viewport::after, .datepicker.android-dark .datepicker-viewport::after{
  background-image: linear-gradient(#ffffff,rgba(245, 245, 245, 0)52%,rgba(245, 245, 245, 0)48%,#ffffff) !important;
}
.datepicker.android, .datepicker.android-dark{
  background-color: white !important;
}
.datepicker.android .datepicker-scroll li, .datepicker.android-dark .datepicker-scroll li{
  font-size: 16px;
}