.mySnackbarWithAction .MuiSnackbarContent-message {
  max-width: calc(100% - 84px) !important;
}

.mySnackbarWithoutAction .MuiSnackbarContent-message {
  width:100% !important;
  max-width: 100% !important;
}

.alertSnackbarWithAction .MuiSnackbarContent-message {
  max-width: calc(100% - 84px) !important;
}


.alertSnackbarWithoutAction .MuiSnackbarContent-message {
  width:100% !important;
  max-width: 100% !important;
}


.alertSnackbarWithAction .MuiPaper-root {
  color: rgb(30, 70, 32);
  background-color: rgb(237, 247, 237);
}


.alertSnackbarWithoutAction .MuiPaper-root {
  color: rgb(30, 70, 32);
  background-color: rgb(237, 247, 237);
}


